import { createColumnHelper } from '@tanstack/react-table'
import FunctionalTable from 'components/FunctionalTable'
import { Field, useFormikContext } from 'formik'
import { useMemo } from 'react'
import { Form } from 'react-bootstrap'
import useData from 'hooks/useData'
import RemoveButton from 'components/RemoveButton'
import { type PatientInfoSchemaType } from 'schemas/patient-info-schema'

/**
 * The table inside the vaccines tab, ideally should be a separate component to deal
 * with the arrayHelpers passed down from Formik's <FieldArray> component
 */
const TestsTable = ({ isEditing, handleAdd, handleRemove, handleChange, handleReplace }) => {
  const { values, isSubmitting, errors } = useFormikContext<Patient>()
  const data = useData()

  const { testTypes, labs, nurses } = {
    testTypes: data.testTypes.all(),
    labs: data.labs.all(),
    nurses: data.nurses.all(),
  }

  const tableData = useMemo(
    () =>
      [...values.tests].map(test => {
        return {
          test: data.testTypes.one(test.type.id)?.title || '',
          date: new Date(test.date),
          lab: data.labs.one(test.lab.id)?.title || '',
          administeredBy: `${data.nurses.one(test.administerer.id)?.title}`,
        }
      }),
    [values]
  )

  const columnHelper = createColumnHelper<(typeof tableData)[number]>()

  const tableColumns = useMemo(
    () => [
      columnHelper.accessor('date', {
        header: 'Date',
        cell: info => (<DateInput rowIndex={info.row.index} isDisabled={!isEditing || isSubmitting} />),
      }),
      columnHelper.accessor('test', {
        header: 'Test',
        cell: info => (<TestInput rowIndex={info.row.index} isDisabled={!isEditing || isSubmitting} />),
      }),
      columnHelper.accessor('lab', {
        header: 'Laboratory',
        cell: info => (<LaboratoryInput rowIndex={info.row.index} isDisabled={!isEditing || isSubmitting} />),
      }),
      columnHelper.accessor('administeredBy', {
        header: 'Administered by',
        cell: info => (<AdministeredByInput rowIndex={info.row.index} isDisabled={!isEditing || isSubmitting} />),
      }),
      columnHelper.display({
        id: 'remove',
        header: '',
        cell: info => (
          <RemoveButton
            disabled={!isEditing || isSubmitting}
            type="button"
            onClick={() => handleRemove(info.row.index)}
          />
        ),
      }),
    ],
    [isEditing, !isEditing || isSubmitting]
  )

  return <FunctionalTable data={tableData} columns={tableColumns} />
}


const DateInput = ({ rowIndex, isDisabled }: TabularInputProps) => {
  const { errors } = useFormikContext<Patient>()

  const typedErrors = useMemo<PatientInfoSchemaType>(() => {return errors as unknown as PatientInfoSchemaType}, [errors])

  const error = typedErrors.tests && typedErrors.tests.length > 0 ? typedErrors.tests[rowIndex]?.date : undefined
  return (
    <>
      <Field
        as={Form.Control}
        disabled={isDisabled}
        type="date"
        name={`tests[${rowIndex}].date`}
        isInvalid={Boolean(error)}
        />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </>
  )
}

const TestInput = ({rowIndex, isDisabled}: TabularInputProps) => {
  const data = useData()

  const testTypes = data.testTypes.all()

  const { errors } = useFormikContext<Patient>()

  const typedErrors = useMemo<PatientInfoSchemaType>(() => {return errors as unknown as PatientInfoSchemaType}, [errors])

  const error = typedErrors.tests && typedErrors.tests.length > 0 ? typedErrors.tests[rowIndex]?.type?.id : undefined

  return (
    <>
      <Field disabled={isDisabled} as={Form.Select} name={`tests[${rowIndex}].type.id`} isInvalid={Boolean(error)}>
        <option value="default" selected disabled>
          Please select
        </option>
        {testTypes.length
          ? testTypes.map(testType => (
              <option key={testType.id} value={testType.id}>
                {testType.title}
              </option>
            ))
          : ''}
      </Field>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </>
  )
}

const LaboratoryInput = ({rowIndex, isDisabled}: TabularInputProps) => {
  const data = useData()

  const labs = data.labs.all()

  const { errors } = useFormikContext<Patient>()

  const typedErrors = useMemo<PatientInfoSchemaType>(() => {return errors as unknown as PatientInfoSchemaType}, [errors])

  const error = typedErrors.tests && typedErrors.tests.length > 0 ? typedErrors.tests[rowIndex]?.lab?.id : undefined

  return (
    <>
      <Field disabled={isDisabled} as={Form.Select} name={`tests[${rowIndex}].lab.id`} isInvalid={Boolean(error)}>
        <option value="default" selected disabled>
          Please select
        </option>
        {labs.length
          ? labs.map(lab => (
              <option key={lab.id} value={lab.id}>
                {lab.title}
              </option>
            ))
          : ''}
      </Field>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </>
  )
}

const AdministeredByInput = ({rowIndex, isDisabled}: TabularInputProps) => {
  const data = useData()

  const nurses = data.nurses.all()

  const { errors } = useFormikContext<Patient>()

  const typedErrors = useMemo<PatientInfoSchemaType>(() => {return errors as unknown as PatientInfoSchemaType}, [errors])

  const error = typedErrors.tests && typedErrors.tests.length > 0 ? typedErrors.tests[rowIndex]?.administerer?.id : undefined

  return (
    <>
      <Field
        disabled={isDisabled}
        as={Form.Select}
        name={`tests[${rowIndex}].administerer.id`}
        isInvalid={Boolean(error)}
      >
        <option value="default" selected disabled>
          Please select
        </option>
        {nurses.map(nurse => (
          <option key={nurse.id} value={nurse.id}>
            {nurse.title}
          </option>
        ))}
      </Field>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </>
  )
}

export default TestsTable
