import { createColumnHelper } from '@tanstack/react-table'
import FunctionalTable from 'components/FunctionalTable'
import { Field, useFormikContext } from 'formik'
import { useMemo } from 'react'
import { Form } from 'react-bootstrap'
import useData from 'hooks/useData'
import RemoveButton from 'components/RemoveButton'
import { PatientInfoSchemaType } from 'schemas/patient-info-schema'

/**
 * The table inside the vaccines tab, ideally should be a separate component to deal
 * with the arrayHelpers passed down from Formik's <FieldArray> component
 */
const PrescriptionsTable = ({ isEditing, handleRemove, handleChange }) => {
  const { values, isSubmitting } = useFormikContext<Patient>()
  const data = useData()

  const tableData = useMemo(
    () =>
      [...values.malariaMedicines].map(medicine => ({
        date: new Date(medicine.dateAdministered),
        quantity: medicine.quantity,
        batchNumber: medicine.batchNumber,
        drug: data.drugs.one(medicine.drug.id)?.title || '',
        administerer: `${data.nurses.one(medicine.administerer.id)?.title || ''}`,
      })),
    [values]
  )

  const columnHelper = createColumnHelper<(typeof tableData)[number]>()

  const tableColumns = useMemo(
    () => [
      columnHelper.accessor('date', {
        header: 'Date',
        cell: info => (<DateInput rowIndex={info.row.index} isDisabled={isSubmitting || !isEditing} />),
      }),
      columnHelper.accessor('drug', {
        header: 'Drug',
        cell: info => (<DrugInput rowIndex={info.row.index} isDisabled={isSubmitting || !isEditing} />),
      }),
      columnHelper.accessor('batchNumber', {
        header: 'Batch no.',
        cell: info => (<BatchNumberInput rowIndex={info.row.index} isDisabled={isSubmitting || !isEditing} />),
      }),
      columnHelper.accessor('quantity', {
        header: 'Quantity',
        cell: info => (<QuantityInput rowIndex={info.row.index} isDisabled={isSubmitting || !isEditing} />),
      }),
      columnHelper.accessor('administerer', {
        header: 'Administered by',
        cell: info => (
          <AdministeredByInput isDisabled={isSubmitting || !isEditing} rowIndex={info.row.index} />
        ),
      }),
      columnHelper.display({
        id: 'remove',
        header: '',
        cell: info => (
          <RemoveButton
            disabled={!isEditing || isSubmitting}
            type="button"
            onClick={() => handleRemove(info.row.index)}
          />
        ),
      }),
    ],
    [isEditing, !isEditing || isSubmitting]
  )

  return <FunctionalTable data={tableData} columns={tableColumns} />
}

const DateInput = ({rowIndex, isDisabled}: TabularInputProps) => {
  const { errors } = useFormikContext<Patient>()

  const typedErrors = useMemo<PatientInfoSchemaType>(() => {return errors as unknown as PatientInfoSchemaType}, [errors])

  const error = typedErrors.malariaMedicines && typedErrors.malariaMedicines.length > 0 ? typedErrors.malariaMedicines[rowIndex]?.dateAdministered : undefined

  return (
    <>
      <Field
        as={Form.Control}
        disabled={isDisabled}
        type="date"
        name={`malariaMedicines[${rowIndex}].dateAdministered`}
        isInvalid={Boolean(error)}
        />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </>
  )
}

const DrugInput = ({rowIndex, isDisabled}: TabularInputProps) => {
  const data = useData()

  const drugs = data.drugs.all()

  const { errors } = useFormikContext<Patient>()

  const typedErrors = useMemo<PatientInfoSchemaType>(() => {return errors as unknown as PatientInfoSchemaType}, [errors])

  const error = typedErrors.malariaMedicines && typedErrors.malariaMedicines.length > 0 ? typedErrors.malariaMedicines[rowIndex]?.drug?.id : undefined

  return (
    <>
      <Field
        disabled={isDisabled}
        as={Form.Select}
        name={`malariaMedicines[${rowIndex}].drug.id`}
        isInvalid={Boolean(error)}
      >
        <option value="default" selected disabled>
          Please select
        </option>
        {drugs.map(drug => (
          <option key={drug.id} value={drug.id}>
            {drug.title}
          </option>
        ))}
      </Field>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </>
  )
}

const BatchNumberInput = ({rowIndex, isDisabled}: TabularInputProps) => {
  const { errors } = useFormikContext<Patient>()

  const typedErrors = useMemo<PatientInfoSchemaType>(() => {return errors as unknown as PatientInfoSchemaType}, [errors])

  const error = typedErrors.malariaMedicines && typedErrors.malariaMedicines.length > 0 ? typedErrors.malariaMedicines[rowIndex]?.batchNumber : undefined

  return (
    <>
      <Field
        disabled={isDisabled}
        as={Form.Control}
        type="text"
        name={`malariaMedicines[${rowIndex}].batchNumber`}
        isInvalid={Boolean(error)}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </>
  )
}

const QuantityInput = ({rowIndex, isDisabled}: TabularInputProps) => {
  const { errors } = useFormikContext<Patient>()

  const typedErrors = useMemo<PatientInfoSchemaType>(() => {return errors as unknown as PatientInfoSchemaType}, [errors])

  const error = typedErrors.malariaMedicines && typedErrors.malariaMedicines.length > 0 ? typedErrors.malariaMedicines[rowIndex]?.quantity : undefined

  return (
    <>
      <Field
        disabled={isDisabled}
        as={Form.Control}
        type="number"
        min={1}
        name={`malariaMedicines[${rowIndex}].quantity`}
        isInvalid={Boolean(error)}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </>
  )
}

const AdministeredByInput = ({rowIndex, isDisabled}: TabularInputProps) => {
  const data = useData()

  const nurses = data.nurses.all()

  const { errors } = useFormikContext<Patient>()

  const typedErrors = useMemo<PatientInfoSchemaType>(() => {return errors as unknown as PatientInfoSchemaType}, [errors])

  const error = typedErrors.malariaMedicines && typedErrors.malariaMedicines.length > 0 ? typedErrors.malariaMedicines[rowIndex]?.administerer?.id : undefined

  return (
    <>
      <Field
        as={Form.Select}
        name={`malariaMedicines[${rowIndex}].administerer.id`}
        disabled={isDisabled}
        isInvalid={Boolean(error)}
      >
        <option value="default" selected disabled>
          Please select
        </option>
        {nurses.map(nurse => (
          <option key={nurse.id} value={nurse.id}>
            {nurse.title}
          </option>
        ))}
      </Field>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </>
  )
}

export default PrescriptionsTable
