import useApi from 'hooks/useApi'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDisplayingPatientId } from 'redux/stateSlice'
import { updateLoadedAppointments } from 'redux/calendarSlice'
import { RootState } from 'redux/store'
import { Phone } from 'components/Icons'
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik'
import { Button, ButtonGroup, CloseButton, Offcanvas } from 'react-bootstrap'
import EditButtons from './EditButtons'
import LoadingCover from 'components/LoadingCover'
import AppointmentsTab from './tabs/AppointmentsTab'
import DocumentsTab from './tabs/DocumentsTab'
import PrescriptionsTab from './tabs/PrescriptionsTab'
import MedsTab from './tabs/MedsTab'
import NotesTab from './tabs/NotesTab'
import DetailsTab from './tabs/DetailsTab'
import TestsTab from './tabs/TestsTab'
import TravelTab from './tabs/TravelTab'
import VaccinesTab from './tabs/VaccinesTab'
import useToast from 'hooks/useToast'
import useModal from 'hooks/useModal'
import patientInfoSchema from 'schemas/patient-info-schema'

export interface PatientTabProps {
  patientDetails: Patient
  isEditing: boolean
}

type TabConfig<T> = Record<string, { tab: React.FC<T>, fields: string[], editable: boolean }>
const tabConfig: TabConfig<PatientTabProps> = {
  'patient': {
    tab: DetailsTab,
    fields: ['firstName', 'lastName', 'title', 'dob', 'nationality', 'telephone', 'mobile', 'email', 'gpPractice'],
    editable: true,
  },
  'appointments': {
    tab: AppointmentsTab,
    fields: [],
    editable: true,
  },
  'pmh/meds': {
    tab: MedsTab,
    fields: [],
    editable: true,
  },
  'trips': {
    tab: TravelTab,
    fields: ['trips'],
    editable: true,
  },
  'vaccines': {
    tab: VaccinesTab,
    fields: ['vaccines'],
    editable: true,
  },
  'prescriptions': {
    tab: PrescriptionsTab,
    fields: ['malariaMedicines'],
    editable: true,
  },
  'tests': {
    tab: TestsTab,
    fields: ['tests'],
    editable: true,
  },
  'docs': {
    tab: DocumentsTab,
    fields: [],
    editable: false,
  },
  'notes': {
    tab: NotesTab,
    fields: ['notes'],
    editable: true,
  },
}

const PatientInfo = () => {
  const dispatch = useDispatch()
  const api = useApi()
  const toast = useToast()

  const { displayingPatientId, loadedStart, loadedEnd } = useSelector((state: RootState) => ({
    displayingPatientId: state.state.displayingPatientId,
    loadedStart: state.calendar.loadedDateRange.start,
    loadedEnd: state.calendar.loadedDateRange.end,
  }))

  // Loading state of the offcanvas panel
  const [isLoading, setIsLoading] = useState(true)

  // Store client data in state
  const [patientDetails, setPatientDetails] = useState<Patient>()

  // Store if the user is editing the form
  const [isEditing, setIsEditing] = useState(false)

  // Store the current active tab
  const [activeTab, setActiveTab] = useState<keyof typeof tabConfig>('patient')

  const loadPatientDetails = async () => {
    setIsLoading(true)
    if (displayingPatientId) {
      setPatientDetails(undefined)
      const _patientDetails = await api.patients.one(displayingPatientId)
      if (_patientDetails.data) {
        const _data = _patientDetails.data

        if (!_data.nationality) {
          _data.nationality = {
            id: 28,
          }
        }

        if (!_data.address.country) {
          _data.address = {
            ..._data.address,
            country: {
              id: 185,
            },
          }
        }

        setPatientDetails(_patientDetails.data)
      } else {
        // TODO: do something with errors
      }
      setActiveTab('patient')
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (displayingPatientId !== null) {
      loadPatientDetails()
    }
  }, [displayingPatientId])

  const handleSubmit = async (values: Patient, formikHelpers: FormikHelpers<Patient>) => {
    const { setSubmitting, resetForm } = formikHelpers
    const saveRequest = await api.patients.save(values.id, JSON.stringify(values))
    if (saveRequest.data) {
      const newDetails = saveRequest.data
      toast.success({
        title: 'Save successful',
        text: `Successfully updated patient information for ${newDetails.firstName} ${newDetails.lastName}`,
      })
      resetForm({
        values: saveRequest.data,
      })
      // Udate loaded appointments with all appointments related to patient in the current date range
      const appointmentsRequest = await api.appointments.range(loadedStart, loadedEnd, newDetails.id)
      if (appointmentsRequest.data) {
        dispatch(updateLoadedAppointments(appointmentsRequest.data))
      }
      setIsEditing(false)
    } else {
      toast.error({
        title: 'Error saving patient',
        text: saveRequest?.errors[0]?.body,
      })
    }
    setSubmitting(false)
  }

  return (
    <Formik
      initialValues={patientDetails as Patient}
      onSubmit={handleSubmit}
      validationSchema={patientInfoSchema}
      validateOnMount
      enableReinitialize
    >
      <PatientInfoContents activeTab={activeTab} setActiveTab={setActiveTab} isEditing={isEditing} setIsEditing={setIsEditing} patientDetails={patientDetails} isLoading={isLoading} />
    </Formik>
  )
}

const PatientInfoContents = ({ activeTab, setActiveTab, patientDetails, isEditing, setIsEditing, isLoading}: {activeTab: string, setActiveTab: Dispatch<SetStateAction<string>>, patientDetails?: Patient, isEditing: boolean, setIsEditing: Dispatch<SetStateAction<boolean>>, isLoading: boolean}) => {
  const { openModal: openDeleteAppointment } = useModal('UNSAVED_PATIENT_CHANGES')

  // const { initialValues, handleSubmit, isSubmitting, dirty, isValid, handleReset, errors } = useFormikContext<Patient>()
  const formikProps = useFormikContext<Patient>()

  const dispatch = useDispatch()
  const { displayingPatientId, loadedStart, loadedEnd } = useSelector((state: RootState) => ({
    displayingPatientId: state.state.displayingPatientId,
    loadedStart: state.calendar.loadedDateRange.start,
    loadedEnd: state.calendar.loadedDateRange.end,
  }))
  
  const renderCurrentTab = () => {
    const TabComponent = tabConfig[activeTab].tab
    return <TabComponent patientDetails={patientDetails as Patient} isEditing={isEditing} />
  }
  
  const handleCloseOffcanvas = () => {
    if (formikProps.dirty) {
      openDeleteAppointment({})
    } else {
      dispatch(setDisplayingPatientId(null))
    }
  }
  
  return (
        <Offcanvas
          show={displayingPatientId !== null}
          onHide={handleCloseOffcanvas}
          placement="end"
          backdropClassName="client-info-backdrop"
          className={`client-info-offcanvas`}
          onExited={() => {
            setIsEditing(false)
            formikProps.handleReset()
          }}
        >
          {isLoading || formikProps.initialValues === undefined ? (
            <LoadingCover text="Loading client details..." />
          ) : formikProps.initialValues !== undefined ? (
            <>
              <Offcanvas.Header className="border-bottom align-items-start">
                <div className="d-flex flex-column gap-3 w-100">
                  <div className="d-flex align-items-start justify-content-between gap-3">
                    <div className="d-flex gap-3">
                      <div className="d-flex flex-column gap-1">
                        <h3 className="mb-0">
                          {formikProps.initialValues.firstName} {formikProps.initialValues.lastName}
                        </h3>
                        <a
                          className="d-flex align-items-center gap-2"
                          href={`tel:${formikProps.initialValues.mobile || formikProps.initialValues.telephone}`}
                        >
                          <Phone size={15} />
                          {formikProps.initialValues.mobile || formikProps.initialValues.telephone}
                        </a>
                      </div>
                      {tabConfig[activeTab].editable ? (
                        <div className="mt-1">
                          <EditButtons
                            dirty={formikProps.dirty}
                            valid={formikProps.isValid}
                            isEditing={isEditing}
                            isSubmitting={formikProps.isSubmitting}
                            setIsEditing={setIsEditing}
                            handleSubmit={formikProps.handleSubmit}
                            handleReset={formikProps.handleReset}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <CloseButton className="m-0" onClick={handleCloseOffcanvas} />
                  </div>
                  <ButtonGroup>
                    <PatientInfoTabs tabConfig={tabConfig} activeTab={activeTab} setActiveTab={setActiveTab} />
                  </ButtonGroup>
                  {process.env.NODE_ENV === 'development' && Object.keys(formikProps.errors).length ? (
                    <>
                      <hr />
                      <pre
                        style={{
                          fontSize: '0.8em',
                          maxHeight: '200px',
                          border: '1px solid red',
                          borderRadius: '6px',
                          padding: '0.5rem',
                        }}
                      >
                        <code>{JSON.stringify(formikProps.errors, null, 2)}</code>
                      </pre>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </Offcanvas.Header>
              <Offcanvas.Body>
                {patientDetails !== undefined ? (
                  <div className="client-details">
                    <Form>{renderCurrentTab()}</Form>
                  </div>
                ) : (
                  ''
                )}
              </Offcanvas.Body>
            </>
          ) : (
            ''
          )}
        </Offcanvas>
      )
}

const PatientInfoTabs = ({tabConfig, activeTab, setActiveTab}: {tabConfig: TabConfig<PatientTabProps>, activeTab: keyof typeof tabConfig, setActiveTab: Dispatch<SetStateAction<keyof typeof tabConfig>>}) => {
  const formikProps = useFormikContext()
  const errorSet = useMemo(() => new Set(Object.keys(formikProps.errors)), [formikProps.errors])

  return (
    <>
    {Object.keys(tabConfig).map((_key, index) => {
      const key = _key as keyof typeof tabConfig // recast due to TS bug using Object.keys
      const doesTabContainErrors = tabConfig[key].fields.some(field => errorSet.has(field));
      return (
        <Button
          active={activeTab === key}
          variant={doesTabContainErrors ? 'danger' : "secondary" }
          key={index}
          onClick={() => setActiveTab(key)}
        >
          {key.toUpperCase()}
        </Button>
      )
    })}
    </>
  )
}

export default PatientInfo
