import * as Yup from 'yup'

// ----------------------------------------- Begin Reusable

const patient = Yup.object({
  id: Yup.string().required(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  dob: Yup.string().optional(),
  telephone: Yup.string().optional(),
  mobile: Yup.string().optional(),
  email: Yup.string().optional(),
})

const administerer = Yup.object({
  id: Yup.string().required('Please choose a nurse'),
  role: Yup.string().optional(),
  email: Yup.string().optional(),
  friendlyName: Yup.string().optional(),
  firstName: Yup.string().optional().nullable(),
  lastName: Yup.string().optional().nullable(),
  lastLoginDate: Yup.string().nullable(),
  status: Yup.string().optional(),
})

const author = Yup.object({
  id: Yup.string().required(),
  craftId: Yup.string().optional(),
  role: Yup.string().optional(),
  active: Yup.string().optional(),
  friendlyName: Yup.string().optional(),
  firstName: Yup.string().optional().nullable(),
  lastName: Yup.string().optional().nullable(),
  lastLoginDate: Yup.string().optional().nullable(),
  status: Yup.string().optional(),
  timeout: Yup.number().optional(),
})

const journey = Yup.object().shape({
  id: Yup.string().optional(),
  arrivalDate: Yup.string().required('Please choose an arrival date'),
  returnDate: Yup.string().when('duration', {
    is: (duration) => !!!duration,
    then: (schema) => schema.required('Please choose a return date (or a length of stay)'),
  }).nullable(),
  duration: Yup.object({
    id: Yup.string(),
    title: Yup.string(),
  }).when('returnDate', {
    is: (returnDate) => !!!returnDate,
    then: (schema) => schema.required('Please choose a length of stay (or a return date)'),
  }).nullable(),
  trip: Yup.object({
    id: Yup.string().optional(),
    ownerId: Yup.string().optional(),
    title: Yup.string().optional(),
    dateCreated: Yup.string().optional(),
    dateUpdated: Yup.string().optional(),
  }).optional(),
  destination: Yup.object({
    id: Yup.string().required('Please select a destination'),
    title: Yup.string().optional(),
  }),
  accommodation: Yup.object({
    id: Yup.string().required('Please select an accomodation style'),
    title: Yup.string().optional(),
    code: Yup.string().optional(),
  }),
}, [['returnDate', 'duration']])

const trip = Yup.object({
  id: Yup.string().optional(),
  title: Yup.string().required('Please provide a trip title in order to save this trip'),
  companions: Yup.array(
    Yup.object({
      id: Yup.string().required(),
      title: Yup.string().optional(),
    })
  ).min(1, 'Please choose at least one travelling companion type for the trip'),
  purposes: Yup.array(
    Yup.object({
      id: Yup.string().required(),
      title: Yup.string().optional(),
    })
  ).min(1, 'Please choose at least one purpose for the trip'),
  journeys: Yup.array(journey),
})

const vaccine = Yup.object({
  id: Yup.number().optional(),
  number: Yup.number().min(1, 'Please choose a number greater than or equal to one').required('Please provide a number for the vaccine'),
  batchNumber: Yup.string().required('Please choose a batch number for this vaccine'),
  prevaccinationchecksCompleted: Yup.number().required('Please choose whether prevaccination checks are completed'),
  date: Yup.string().required(),
  type: Yup.object({
    id: Yup.string().required('Please choose a vaccine type'),
    title: Yup.string().optional(),
  }),
  drug: Yup.object({
    id: Yup.string().required('Please choose a drug type (choose vaccine type first)'),
    title: Yup.string().optional(),
    vaccineTypeId: Yup.string().optional(),
  }),
  site: Yup.object({
    id: Yup.string().required('Please choose a site to administer the vaccine'),
    title: Yup.string().optional(),
  }),
  route: Yup.object({
    id: Yup.string().required('Please choose a route'),
    title: Yup.string().optional(),
  }),
  administerer,
  author,
  dateCreated: Yup.string().optional(),
})

const malariaMedicine = Yup.object({
  id: Yup.string().optional(),
  drug: Yup.object({
    id: Yup.string().required('Please choose a drug for this prescription'),
    title: Yup.string().optional(),
  }),
  quantity: Yup.number().min(1, 'Please choose a quantity greater than or equal to one').required('Please choose a quantity for this prescription'),
  batchNumber: Yup.string().required('Please choose a batch number for this prescription'),
  dateAdministered: Yup.string().required('Please choose a date for this prescription'),
  administerer,
})

const test = Yup.object({
  id: Yup.string().optional(),
  date: Yup.string().required('Please choose a date for the test'),
  type: Yup.object({
    id: Yup.string().required('Please choose a test type'),
    title: Yup.string().optional(),
  }),
  lab: Yup.object({
    id: Yup.string().required('Please choose a laboratory'),
    title: Yup.string().optional(),
  }),
  administerer,
})

const note = Yup.object({
  id: Yup.string().optional(),
  note: Yup.string().optional(),
  author,
  travelHealthInfo: Yup.object({
    id: Yup.string().optional(),
    bite_avoidance: Yup.number().required(),
    personal_safety: Yup.number().required(),
    side_effects: Yup.number().required(),
    infectious_diseases: Yup.number().required(),
    insurance: Yup.number().required(),
    sun_environment: Yup.number().required(),
    food_water_hygiene: Yup.number().required(),
    emailed_info: Yup.number().required(),
  }),
  dateCreated: Yup.string().optional(),
  newInformation: Yup.string().test('New information', 'Please add some details to the note or select an option that applies', function (val) {
    const { note, travelHealthInfo } = this.parent
    return (
      note ||
      Object.entries(travelHealthInfo)
        .map(([key, value]) => value)
        .filter(val => val).length > 0
    )
  }),
})

const appointment = Yup.object({
  id: Yup.string().optional(),
  startDate: Yup.string().required(),
  endDate: Yup.string().required(),
  title: Yup.string().optional(),
  patients: Yup.array(patient),
  room: Yup.object({
    id: Yup.string().required(),
    title: Yup.string().optional(),
  }),
  type: Yup.object({
    id: Yup.string().required(),
    title: Yup.string().optional(),
  }),
  status: Yup.object({
    id: Yup.string().required(),
    title: Yup.string().optional(),
  }),
  notes: Yup.array(
    Yup.object({
      room: Yup.object({
        id: Yup.string().optional(),
        note: Yup.string().optional(),
      }),
    })
  ),
})

const document = Yup.object({
  id: Yup.string().required(),
  assteId: Yup.string().required(),
  fileName: Yup.string().required(),
  fileSize: Yup.number().required(),
  lastModifiedDate: Yup.string().required(),
  url: Yup.string().required(),
  uploader: administerer.required(),
})

// ----------------------------------------- End Reusable

// ========================================= Begin Fields

const title = Yup.object({
  id: Yup.string(),
  title: Yup.string().optional(),
})

const nationality = Yup.object({
  id: Yup.string(),
  title: Yup.string().optional(),
})

const address = Yup.object({
  id: Yup.string().nullable(),
  addressLine1: Yup.string().nullable(),
  addressLine2: Yup.string().nullable(),
  townCity: Yup.string().nullable(),
  county: Yup.string().nullable(),
  postalCode: Yup.string().nullable(),
  resident: Yup.string().nullable(),
  country: Yup.object({
    id: Yup.string().required().nullable(),
    title: Yup.string().optional(),
  }).nullable(),
})

const medicalHistory = Yup.object({
  id: Yup.string().nullable(),
  ownerId: Yup.string().nullable(),
  respiratory_illness: Yup.number().required().nullable(),
  respiratory_illness_details: Yup.string().defined().optional(),
  heart_disease: Yup.number().required().nullable(),
  heart_disease_details: Yup.string().defined().optional(),
  diabetes_or_epilepsy: Yup.number().required().nullable(),
  diabetes_or_epilepsy_details: Yup.string().defined().optional(),
  gastro_liver_or_kidney: Yup.number().required().nullable(),
  gastro_liver_or_kidney_details: Yup.string().defined().optional(),
  immunosuppression: Yup.number().required().nullable(),
  immunosuppression_details: Yup.string().defined().optional(),
  mental_health: Yup.number().required().nullable(),
  mental_health_details: Yup.string().defined().optional(),
  other: Yup.number().required().nullable(),
  other_details: Yup.string().defined().optional(),
  breast_feeding_pregnancy: Yup.number().required().nullable(),
  breast_feeding_pregnancy_details: Yup.string().defined().optional(),
  allergies: Yup.number().required().nullable(),
  allergies_details: Yup.string().defined().optional(),
  medication: Yup.number().required().nullable(),
  medication_details: Yup.string().defined().optional(),
})

const preVaccinationChecks = Yup.object({
  id: Yup.string().nullable(),
  ownerId: Yup.string().nullable(),
  all_checked: Yup.number().required(),
  allergies: Yup.number().required(),
  allergies_details: Yup.string().optional(),
  live_vaccines: Yup.number().required(),
  live_vaccines_details: Yup.string().optional(),
  immunocompetent: Yup.number().required(),
  immunocompetent_details: Yup.string().optional(),
  pmh_meds_nad: Yup.number().required(),
  pmh_meds_nad_details: Yup.string().optional(),
  contraindications: Yup.number().required(),
  contraindications_details: Yup.string().optional(),
  childhood_vaccines: Yup.number().required(),
  childhood_vaccines_details: Yup.string().optional(),
  feeling_well: Yup.number().required(),
  feeling_well_details: Yup.string().optional(),
  yf_checklist_read: Yup.number().required(),
  yf_checklist_read_details: Yup.string().optional(),
})

// Main schema
const patientInfoSchema = Yup.object().shape({
  id: Yup.string().required(),
  uid: Yup.string().optional(),
  firstName: Yup.string().required('Please provide a first name'),
  lastName: Yup.string().required('Please provide a last name'),
  dob: Yup.string().required('Please provide a valid date of birth'),
  email: Yup.string().when(['mobile', 'telephone'], {
    is: (mobile, telephone) => mobile || telephone,
    then: (schema) => schema.email('Please check that the email you have provided is valid'),
    otherwise: (schema) => schema.email('Please check that the email you have provided is valid').required('Please provide a valid email address')
  }),
  mobile: Yup.string().when(['email', 'telephone'], {
    is: (email, telephone) => email || telephone,
    then: (schema) => schema.optional(),
    otherwise: (schema) => schema.required('Please provide some contact information for this patient')
  }),
  telephone: Yup.string().when(['email', 'mobile'], {
    is: (email, mobile) => email || mobile,
    then: (schema) => schema.optional(),
    otherwise: (schema) => schema.required('Please provide some contact information for this patient')
  }),
  title: title.nullable(),
  nationality: nationality.nullable(),
  address: address.required(),
  gpPractice: Yup.string().nullable(),
  consentToGDPR: Yup.boolean().optional(),
  consentParentGuardian: Yup.boolean().optional(),
  // PMH/MEDS
  medicalHistory: medicalHistory.required(),
  // Trips
  trips: Yup.array(trip).required(),
  // Vaccines
  vaccines: Yup.array(vaccine).required(),
  preVaccinationChecks: preVaccinationChecks.required(),
  // Prescriptions
  malariaMedicines: Yup.array(malariaMedicine).required(),
  // Tests
  tests: Yup.array(test).required(),
  // Notes
  notes: Yup.array(note).required(),
  // documents: Yup.array(document).required(),
},[
  ['mobile', 'telephone'],
  ['email', 'telephone'],
  ['email', 'mobile']
])

export default patientInfoSchema
export type PatientInfoSchemaType = Yup.InferType<typeof patientInfoSchema>
