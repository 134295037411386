import * as Yup from 'yup'

// Yup currently has a bug where .test on schema objects does not work with formik
// This is why 'patientValidation' is inline inside the schemas themselves
//
// --> @see https://github.com/jaredpalmer/formik/issues/2146 for more information

const basePatientSchema = Yup.object().shape({
  firstName: Yup.string().required('New patients must have a first name.'),
  lastName: Yup.string().required('New patients must have a last name.'),
  dob: Yup.string().required('New patients must have a date of birth.'),
  email: Yup.string().when(['mobile', 'telephone'], {
    is: (mobile, telephone) => mobile || telephone,
    then: (schema) => schema.email('Patient must have a valid email address'),
    otherwise: (schema) => schema.email('Patient must have a valid email address').required('A patient must have some contact information')
  }),
  mobile: Yup.string().when(['email', 'telephone'], {
    is: (email, telephone) => email || telephone,
    then: (schema) => schema.optional(),
    otherwise: (schema) => schema.required('A patient must have some contact information')
  }),
  telephone: Yup.string().when(['email', 'mobile'], {
    is: (email, mobile) => email || mobile,
    then: (schema) => schema.optional(),
    otherwise: (schema) => schema.required('A patient must have some contact information')
  }),
}, [
  ['mobile', 'telephone'],
  ['email', 'telephone'],
  ['email', 'mobile']
])

// Extend base patient schema
const newPatientSchema = basePatientSchema.shape({
  sendRegistrationEmail: Yup.number().min(0).max(1).integer().required(),
  isTravelling: Yup.number().min(0).max(1).integer().required(),
})

// Extend base patient schema
const existingPatientSchema = basePatientSchema.shape({
  id: Yup.string().required(),
})

const appointmentNote = Yup.object({
  id: Yup.number().optional(),
  appointmentId: Yup.number().optional(),
  authorId: Yup.number().optional(),
  note: Yup.string(),
})

const appointmentEditSchema = Yup.object({
  id: Yup.string().optional(),
  title: Yup.string().optional(),
  startDate: Yup.string().required(),
  endDate: Yup.string().required("You haven't selected a timeslot."),
  roomId: Yup.string().required(),
  typeId: Yup.string().required(),
  statusId: Yup.string().required(),
  existingPatients: Yup.array().of(existingPatientSchema.required()).defined(),
  newPatients: Yup.array().of(newPatientSchema.required()).defined(),
  patientValidation: Yup.boolean().test('At least 1 patient', 'You must select at least 1 patient', function (val) {
    const { existingPatients, newPatients } = this.parent
    return existingPatients.length + newPatients.length >= 1
  }),
  notes: Yup.array().of(appointmentNote).required(),
})

export default appointmentEditSchema
export type AppointmentEditSchemaType = Yup.InferType<typeof appointmentEditSchema>
