import { Field, useFormikContext } from 'formik'
import { Button, Col, FloatingLabel, Form, Row, Spinner, InputGroup } from 'react-bootstrap'
import FormSection from '../FormSection'
import { PatientTabProps } from '../PatientInfo'
import useData from 'hooks/useData'
import useApi from 'hooks/useApi'
import useToast from 'hooks/useToast'
import { useEffect, useState } from 'react'
import { Email } from 'components/Icons'
import moment from 'moment'
import { BiCake } from 'react-icons/bi'

const DetailsTab: React.FC<PatientTabProps> = ({ isEditing }) => {
  const { values, isSubmitting, setFieldValue, errors } = useFormikContext<Patient>()
  const data = useData()
  const api = useApi()
  const toast = useToast()

  const { nationalities, countries, titles } = {
    nationalities: data.nationalities.all(),
    countries: data.countries.all(),
    titles: data.titles.all(),
  }

  const [ isSending, setIsSending ] = useState(false)
  const [ lastRegistrationEmail, setLastRegistrationEmail ] = useState<BasicNotification|undefined>(undefined)
  const [ age, setAge ] = useState('n/a')
  const [ bday, setBday ] = useState(false)


  // NOTE: Review further in future - want this to be specific to just email??
  const handleSendEmail = async () => {
    setIsSending(true)

    const resendRequest = await api.patients.sendRegistration(values.id)

    // Error
    if (resendRequest.data?.notifications.length == 0) {
      toast.error({
        title: 'Error sending notification',
        text: '',
      })
      setIsSending(false)
      return
    }

    // Success
    const recipients = [...new Set(resendRequest.data?.notifications.map(n => n.recipients).flat())]
    toast.success({
      title: 'Notification Successful',
      text: `Successfully resent registration notification to ${recipients.join(', ')}`,
    })

    _setLastRegistrationEmail(resendRequest.data?.notifications)
    setIsSending(false)
  }

  // Simple wrapper for setLastRegistrationEmail - could be done better
  const _setLastRegistrationEmail = (notifications: BasicNotification[]|undefined) => {
    let email = undefined as BasicNotification|undefined
    if (notifications) {
      email = notifications.find(n => n.type == 'new_patient' && n.medium == 'email')
    }

    setLastRegistrationEmail(email)
  }

  useEffect(() => {
    _setLastRegistrationEmail(values.notifications)
  }, [])

  useEffect(() => {
    const dob = moment(values.dob)
    const now = moment()
    const diff = now.diff(dob, 'days')
    const duration = moment.duration(diff, 'days')
    const _age = duration.days() >= 0
      ? duration.years() + 'y ' + duration.months() + 'm'
      : 'n/a'

    setBday(dob.date() === now.date() && dob.month() === now.month())
    setAge(_age)
  }, [values.dob])

  return (
    <div className="w-100">
      <Row className="mb-5 g-5">
        <Col xs={6} className="patient-details">
          <FormSection title="Patient Details">
            <div className="d-flex flex-column gap-2">
              <FloatingLabel label="First name" controlId="patient:firstName">
                <Field disabled={!isEditing || isSubmitting} as={Form.Control} name="firstName" isInvalid={!!errors.firstName} />
                <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel label="Last name" controlId="patient:lastName">
                <Field disabled={!isEditing || isSubmitting} as={Form.Control} name="lastName" isInvalid={!!errors.lastName} />
                <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel label="Title" controlId="patient:title">
                <Field disabled={!isEditing || isSubmitting} as={Form.Select} name="title.id" isInvalid={!!errors.title} >
                  <option value="default" disabled>Select a title</option>
                  { titles.length && titles.map(title => (
                    <option key={title.id} value={title.id}>{title.title}</option>
                  )) }
                </Field>
                <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
              </FloatingLabel>

            <Row>
              <Col xs={8}>
                <FloatingLabel label="Date of Birth" controlId="patient:dob">
                  <Field disabled={!isEditing || isSubmitting} as={Form.Control} type="date" name="dob" isInvalid={!!errors.dob}/>
                  <Form.Control.Feedback type="invalid">{errors.dob}</Form.Control.Feedback>
                </FloatingLabel>
              </Col>

              <Col xs={4}>
                <InputGroup>
                  <FloatingLabel label="Age" controlId="patient:age">
                    <Field disabled={true} as={Form.Control} name="age" value={age} className="border-end-0" />
                  </FloatingLabel>
                  <InputGroup.Text>{bday ? <BiCake></BiCake> : ''}</InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>

              <FloatingLabel label="Nationality" controlId="patient:nationality">
                <Field disabled={!isEditing || isSubmitting} as={Form.Select} name="nationality.id" isInvalid={!!errors.nationality}>
                  <option value="default" disabled>Select a nationality</option>
                  { nationalities.length && nationalities.map(nationality => (
                    <option key={nationality.id} value={nationality.id}>{nationality.title}</option>
                  )) }
                </Field>
                <Form.Control.Feedback type="invalid">{errors.nationality}</Form.Control.Feedback>
              </FloatingLabel>
            </div>
          </FormSection>
        </Col>

        <Col xs={6} className="patient-address">
          <FormSection title="Home Address">
            <div className="d-flex flex-column gap-2">
              <FloatingLabel label="Address Line 1" controlId="patient:address.line1">
                <Field disabled={!isEditing || isSubmitting} as={Form.Control} name="address.addressLine1" isInvalid={!!errors.address?.addressLine1} />
                <Form.Control.Feedback type="invalid">{errors.address?.addressLine1}</Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel label="Address Line 2" controlId="patient:address.line2">
                <Field disabled={!isEditing || isSubmitting} as={Form.Control} name="address.addressLine2" isInvalid={!!errors.address?.addressLine2} />
                <Form.Control.Feedback type="invalid">{errors.address?.addressLine2}</Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel label="Town" controlId="patient:address.town">
                <Field disabled={!isEditing || isSubmitting} as={Form.Control} name="address.townCity" isInvalid={!!errors.address?.townCity} />
                <Form.Control.Feedback type="invalid">{errors.address?.townCity}</Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel label="County" controlId="patient:address.county">
                <Field disabled={!isEditing || isSubmitting} as={Form.Control} name="address.county" isInvalid={!!errors.address?.county} />
                <Form.Control.Feedback type="invalid">{errors.address?.county}</Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel label="Postcode" controlId="patient:address.postcode">
                <Field disabled={!isEditing || isSubmitting} as={Form.Control} name="address.postalCode" isInvalid={!!errors.address?.postalCode} />
                <Form.Control.Feedback type="invalid">{errors.address?.postalCode}</Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel label="Country" controlId="patient:country">
                <Field disabled={!isEditing || isSubmitting} as={Form.Select} name="address.country.id" isInvalid={!!errors.address?.country}>
                  <option value="default" disabled>Select a country</option>
                  { countries.length && countries.map(country => (
                    <option key={country.id} value={country.id}>{country.title}</option>
                  )) }
                </Field>
                <Form.Control.Feedback type="invalid">{errors.address?.country}</Form.Control.Feedback>
              </FloatingLabel>
            </div>
          </FormSection>
        </Col>

        <Col xs={6} className="patient-contact">
          <FormSection title="Contact Details">
            <div className="d-flex flex-column gap-2">
              <FloatingLabel label="Telephone" controlId="patient:telephone">
                <Field disabled={!isEditing || isSubmitting} as={Form.Control} key={'patient-info-1'} name="telephone" isInvalid={!!errors.telephone} />
                <Form.Control.Feedback type="invalid">{errors.telephone}</Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel label="Mobile" controlId="patient:mobile">
                <Field disabled={!isEditing || isSubmitting} as={Form.Control} key={'patient-info-1'} name="mobile" isInvalid={!!errors.mobile} />
                <Form.Control.Feedback type="invalid">{errors.mobile}</Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel label="Email" controlId="patient:email">
                <Field disabled={!isEditing || isSubmitting} as={Form.Control} key={'patient-info-1'} name="email" isInvalid={!!errors.email} />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel label="GP Practice" controlId="patient:gpPractice">
                <Field disabled={!isEditing || isSubmitting} as={Form.Control} key={'patient-info-1'} name="gpPractice" isInvalid={!!errors.gpPractice} />
                <Form.Control.Feedback type="invalid">{errors.gpPractice}</Form.Control.Feedback>
              </FloatingLabel>
            </div>
          </FormSection>
        </Col>

        <Col xs={6} className="patient-consent">
          <FormSection title="Consent">
            <div className="d-flex flex-column gap-2">
              <Field
                className="mb-3"
                disabled={!isEditing || isSubmitting}
                as={Form.Check}
                size="lg"
                label="Patient acknowledges the Data Privacy policy"
                id="patient-consent-gdpr"
                checked={values.consentToGDPR}
                value={values.consentToGDPR}
                onChange={e => setFieldValue('consentToGDPR', Number(e.target.checked))}
              />
            </div>
            <div className="d-flex flex-column gap-2">
              <Field
                className="mb-3"
                disabled={!isEditing || isSubmitting}
                as={Form.Check}
                size="lg"
                label="Patient is under 16 years old - parent/guardian has provided consent"
                id="patient-consent-guardian"
                checked={values.consentParentGuardian}
                value={values.consentParentGuardian}
                onChange={e => setFieldValue('consentParentGuardian', Number(e.target.checked))}
              />
            </div>
          </FormSection>

          <FormSection title="Notifications" className="mt-5">
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="d-flex flex-column">
                Registration
                { lastRegistrationEmail ?
                  (<span className="text-muted form-text fw-normal">Registration notification was last sent to <em>{lastRegistrationEmail.recipients.join(', ') }</em> on {lastRegistrationEmail.lastSent}</span>) :
                  (<span className="text-muted form-text fw-normal">Patient has not yet received this notification.</span>)
                }
              </h6>

              <Button size="sm" disabled={isSending} onClick={handleSendEmail}>
                { isSending ?
                  <>Sending <Spinner size="sm" /></> :
                  <>{ lastRegistrationEmail ? 'Resend' : 'Send' } <Email fontSize="1.5em" /></>
                }
              </Button>
            </div>
          </FormSection>
        </Col>
      </Row>
    </div>
  )
}

export default DetailsTab
