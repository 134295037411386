import { createColumnHelper } from '@tanstack/react-table'
import FunctionalTable from 'components/FunctionalTable'
import { Field, useFormikContext } from 'formik'
import { useMemo } from 'react'
import { Form } from 'react-bootstrap'
import useData from 'hooks/useData'
import RemoveButton from 'components/RemoveButton'
import useHelpers from 'hooks/useHelpers'
import useCanEdit from 'hooks/useCanEdit'
import { type PatientInfoSchemaType } from 'schemas/patient-info-schema'

/**
 * The table inside the vaccines tab, ideally should be a separate component to deal
 * with the arrayHelpers passed down from Formik's <FieldArray> component
 */
const VaccinesTable = ({ isEditing, handleAdd, handleRemove, handleReplace }) => {
  const { values, isSubmitting, setFieldValue } = useFormikContext<Patient>()
  const data = useData()

  const canEdit = useCanEdit()

  const { nurses } = {
    nurses: data.nurses.all(),
  }

  const tableData = useMemo(
    () =>
      [...values.vaccines].map(vaccine => ({
        date: new Date(vaccine.date),
        type: vaccine.type.title || '',
        drug: vaccine.drug?.title || '',
        site: vaccine.site.title || '',
        route: vaccine.route.title || '',
        number: vaccine.number,
        batchNumber: vaccine.batchNumber,
        prevaccinationchecksCompleted: vaccine.prevaccinationchecksCompleted,
        administeredBy: `${data.nurses.one(vaccine.administerer.id)?.title}`,
        addedBy: {
          id: vaccine.author.id,
          title: `${vaccine.author.firstName || ''} ${vaccine.author.lastName || ''}`.trim() || vaccine.author.friendlyName,
        },
        dateCreated: new Date(vaccine.dateCreated),
      })),
    [values]
  )

  const columnHelper = createColumnHelper<(typeof tableData)[number]>()

  const tableColumns = useMemo(
    () => [
      columnHelper.accessor('date', {
        header: 'Date',
        cell: info => (<DateInput rowIndex={info.row.index} isDisabled={isSubmitting || !isEditing || !canEdit(info.row.original.addedBy.id, info.row.original.dateCreated)} />),
      }),
      columnHelper.accessor('type', {
        header: 'Type',
        cell: info => (<TypeInput rowIndex={info.row.index} isDisabled={isSubmitting || !isEditing || !canEdit(info.row.original.addedBy.id, info.row.original.dateCreated)} />),
      }),

      columnHelper.accessor('drug', {
        header: 'Drug',
        cell: info => (<DrugInput rowIndex={info.row.index} isDisabled={isSubmitting || !isEditing || !canEdit(info.row.original.addedBy.id, info.row.original.dateCreated)} />),
      }),
      columnHelper.accessor('site', {
        header: 'Site',
        cell: info => (<SiteInput rowIndex={info.row.index} isDisabled={!isEditing || !canEdit(info.row.original.addedBy.id, info.row.original.dateCreated) || isSubmitting} />),
      }),
      columnHelper.accessor('route', {
        header: 'Route',
        cell: info => (<RouteInput rowIndex={info.row.index} isDisabled={!isEditing || !canEdit(info.row.original.addedBy.id, info.row.original.dateCreated) || isSubmitting} />),
      }),
      columnHelper.accessor('number', {
        header: 'No.',
        cell: info => (<NumberInput rowIndex={info.row.index} isDisabled={!isEditing || isSubmitting || !canEdit(info.row.original.addedBy.id, info.row.original.dateCreated)} />),
      }),
      columnHelper.accessor('batchNumber', {
        header: 'Batch no.',
        cell: info => (<BatchNumberInput rowIndex={info.row.index} isDisabled={!isEditing || !canEdit(info.row.original.addedBy.id, info.row.original.dateCreated) || isSubmitting} />),
      }),
      columnHelper.accessor('prevaccinationchecksCompleted', {
        header: 'PVC',
        cell: info => (<PVCInput rowIndex={info.row.index} isDisabled={!isEditing || !canEdit(info.row.original.addedBy.id, info.row.original.dateCreated) || isSubmitting} isChecked={info.row.getValue('prevaccinationchecksCompleted') == 1} />),
      }),
      columnHelper.accessor('administeredBy', {
        header: 'Administered by',
        cell: info => (<AdministeredByInput rowIndex={info.row.index} isDisabled={!isEditing || !canEdit(info.row.original.addedBy.id, info.row.original.dateCreated) || isSubmitting} />),
      }),
      columnHelper.display({
        id: 'remove',
        header: '',
        cell: info => (
          isEditing &&
          canEdit(info.row.original.addedBy.id, info.row.original.dateCreated) &&
          (
            <RemoveButton
              type="button"
              disabled={isSubmitting}
              onClick={() => handleRemove(info.row.index)}
            />
          )
        ),
      }),
    ],
    [values.vaccines.length, isEditing, !isEditing || isSubmitting]
  )

  return <FunctionalTable data={tableData} columns={tableColumns} />
}

const DateInput = ({rowIndex, isDisabled}: TabularInputProps) => {
  const { errors } = useFormikContext<Patient>()

  const typedErrors = useMemo<PatientInfoSchemaType>(() => {return errors as unknown as PatientInfoSchemaType}, [errors])

  const error = typedErrors.vaccines && typedErrors.vaccines.length > 0 ? typedErrors.vaccines[rowIndex]?.date : undefined

  return (
    <>
      <Field
        disabled={isDisabled}
        as={Form.Control}
        type="date"
        name={`vaccines[${rowIndex}].date`}
        isInvalid={Boolean(error)}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </>
  )
}

const TypeInput = ({rowIndex, isDisabled}: TabularInputProps) => {
  const { values, errors, setFieldValue, handleChange } = useFormikContext<Patient>()

  const typedErrors = useMemo<PatientInfoSchemaType>(() => {return errors as unknown as PatientInfoSchemaType}, [errors])

  const error = typedErrors.vaccines && typedErrors.vaccines.length > 0 ? typedErrors.vaccines[rowIndex]?.type?.id : undefined

  const data = useData()
  const vaccineTypes = data.vaccineTypes.all()
  const helpers = useHelpers()

  return (
    <>
      <Field
        disabled={isDisabled}
        as={Form.Select}
        name={`vaccines[${rowIndex}].type.id`}
        onChange={e => {
          handleChange(e)
          const newVaccineType = e.target.value

          const newlyValidVaccineSubtypes = helpers.data.getVaccineSubTypes(newVaccineType)
          let newVaccineSubType = values.vaccines[rowIndex].drug.id
          if (newlyValidVaccineSubtypes.length > 0) {
            newVaccineSubType = newlyValidVaccineSubtypes[0].id
          }
          setFieldValue(`vaccines[${rowIndex}].type.id`, newVaccineType)
          setFieldValue(`vaccines[${rowIndex}].drug.id`, newVaccineSubType)
        }}
        isInvalid={Boolean(error)}
      >
        <option value="default" selected disabled>
          Please select
        </option>
        {vaccineTypes.map(type => (
          <option key={type.id} value={type.id}>
            {type.title}
          </option>
        ))}
      </Field>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </>
  )
}

const DrugInput = ({rowIndex, isDisabled}: TabularInputProps) => {
  const { values, errors } = useFormikContext<Patient>()

  const typedErrors = useMemo<PatientInfoSchemaType>(() => {return errors as unknown as PatientInfoSchemaType}, [errors])

  const error = typedErrors.vaccines && typedErrors.vaccines.length > 0 ? typedErrors.vaccines[rowIndex]?.drug?.id : undefined

  const helpers = useHelpers()

  return (
    <>
      <Field
        disabled={isDisabled}
        as={Form.Select}
        name={`vaccines[${rowIndex}].drug.id`}
        isInvalid={Boolean(error)}
      >
        <option value="default" selected disabled>
          Please select
        </option>
        {helpers.data.getVaccineSubTypes(values.vaccines[rowIndex].type.id).map(subType => (
          <option key={subType.id} value={subType.id}>
            {subType.title}
          </option>
        ))}
      </Field>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </>
  )
}

const SiteInput = ({rowIndex, isDisabled}: TabularInputProps) => {
  const { errors } = useFormikContext<Patient>()

  const typedErrors = useMemo<PatientInfoSchemaType>(() => {return errors as unknown as PatientInfoSchemaType}, [errors])

  const error = typedErrors.vaccines && typedErrors.vaccines.length > 0 ? typedErrors.vaccines[rowIndex]?.site?.id : undefined

  const data = useData()

  const vaccineSites = data.vaccineSites.all()

  return (
    <>
      <Field
        disabled={isDisabled}
        as={Form.Select}
        name={`vaccines[${rowIndex}].site.id`}
        isInvalid={Boolean(error)}  
      >
        <option value="default" selected disabled>
          Please select
        </option>
        {vaccineSites.map(site => (
          <option key={site.id} value={site.id}>
            {site.title}
          </option>
        ))}
      </Field>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </>
  )
}

const RouteInput = ({rowIndex, isDisabled}: TabularInputProps) => {
  const { errors } = useFormikContext<Patient>()

  const typedErrors = useMemo<PatientInfoSchemaType>(() => {return errors as unknown as PatientInfoSchemaType}, [errors])

  const error = typedErrors.vaccines && typedErrors.vaccines.length > 0 ? typedErrors.vaccines[rowIndex]?.route?.id : undefined

  const data = useData()

  const vaccineRoutes = data.vaccineRoutes.all()

  return (
    <>
      <Field
        disabled={isDisabled}
        as={Form.Select}
        name={`vaccines[${rowIndex}].route.id`}
        isInvalid={Boolean(error)}>
        <option value="default" selected disabled>
          Please select
        </option>
        {vaccineRoutes.map(route => (
          <option key={route.id} value={route.id}>
            {route.title}
          </option>
        ))}
      </Field>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </>
  )
}

const NumberInput = ({rowIndex, isDisabled}: TabularInputProps) => {
  const { errors } = useFormikContext<Patient>()

  const typedErrors = useMemo<PatientInfoSchemaType>(() => {return errors as unknown as PatientInfoSchemaType}, [errors])

  const error = typedErrors.vaccines && typedErrors.vaccines.length > 0 ? typedErrors.vaccines[rowIndex]?.number : undefined

  return (
    <>
      <Field
        type="number"
        min="1"
        // NOTE: Updated to allow more digits to be seen
        max="99999"
        disabled={isDisabled}
        as={Form.Control}
        name={`vaccines[${rowIndex}].number`} // TODO: check this
        isInvalid={Boolean(error)}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </>
  )
}

const BatchNumberInput = ({rowIndex, isDisabled}: TabularInputProps) => {
  const { errors } = useFormikContext<Patient>()

  const typedErrors = useMemo<PatientInfoSchemaType>(() => {return errors as unknown as PatientInfoSchemaType}, [errors])

  const error = typedErrors.vaccines && typedErrors.vaccines.length > 0 ? typedErrors.vaccines[rowIndex]?.batchNumber : undefined

  return (
    <>
      <Field
        disabled={isDisabled}
        as={Form.Control}
        type="text"
        name={`vaccines[${rowIndex}].batchNumber`}
        isInvalid={Boolean(error)}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </>
  )
}

const PVCInput = ({rowIndex, isDisabled, isChecked}: TabularInputProps & {isChecked: boolean}) =>{ 
  const { errors, setFieldValue } = useFormikContext<Patient>()

  const typedErrors = useMemo<PatientInfoSchemaType>(() => {return errors as unknown as PatientInfoSchemaType}, [errors])

  const error = typedErrors.vaccines && typedErrors.vaccines.length > 0 ? typedErrors.vaccines[rowIndex]?.prevaccinationchecksCompleted : undefined

  return (
    <>
      <div className="d-flex align-items-center justify-content-center">
        <Field
          disabled={isDisabled}
          as={Form.Check}
          size="lg"
          checked={isChecked}
          value=""
          onChange={e => setFieldValue(`vaccines[${rowIndex}].prevaccinationchecksCompleted`, Number(e.target.checked))}
          isInvalid={Boolean(error)}
        />
      </div>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </>
  )
}

const AdministeredByInput = ({rowIndex, isDisabled}: TabularInputProps) => {
  const data = useData()

  const nurses = data.nurses.all()

  const { errors } = useFormikContext<Patient>()

  const typedErrors = useMemo<PatientInfoSchemaType>(() => {return errors as unknown as PatientInfoSchemaType}, [errors])

  const error = typedErrors.vaccines && typedErrors.vaccines.length > 0 ? typedErrors.vaccines[rowIndex]?.administerer?.id : undefined

  return (
    <>
      <Field
        // TODO: review UI for this? maybe something more contextual like a badge??
        as={Form.Select}
        name={`vaccines[${rowIndex}].administerer.id`}
        disabled={isDisabled}
        isInvalid={Boolean(error)}
        >
        <option value="default" selected disabled>
          Please select
        </option>
        {nurses.map(nurse => (
          <option key={nurse.id} value={nurse.id}>
            {nurse.title}
          </option>
        ))}
      </Field>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </>
  )
}

export default VaccinesTable
