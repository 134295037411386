import * as Yup from 'yup'

// Yup currently has a bug where .test on schema objects does not work with formik
// This is why 'contactValidation' and 'patientValidation' are both inline inside
// the schemas themselves
// --> @see https://github.com/jaredpalmer/formik/issues/2146 for more information

const basePatientSchema = Yup.object({
  firstName: Yup.string().required('Please ensure new patients have a first name'),
  lastName: Yup.string().required('Please ensure new patients have a last name'),
  dob: Yup.string().required('Please ensure new patients have a date of birth'),
  email: Yup.string().email('Please check that the email you have provided is valid').required('Please ensure new patients have an email'),
  mobile: Yup.string().optional(),
  telephone: Yup.string().optional(),
  contactValidation: Yup.boolean().test(
    'At least email or telephone',
    'Please provide at least one of mobile number or telephone number',
    function (val) {
      const { telephone, mobile } = this.parent
      return telephone !== undefined || mobile !== undefined
    }
  ),
})

// Extend base patient schema
const newPatientSchema = basePatientSchema.shape({
  sendRegistrationEmail: Yup.number().min(0).max(1).integer().required(),
  isTravelling: Yup.number().min(0).max(1).integer().required(),
})

// Extend base patient schema
const existingPatientSchema = basePatientSchema.shape({
  id: Yup.string().required(),
})

const appointmentNote = Yup.object({
  id: Yup.number().optional(),
  appointmentId: Yup.number().optional(),
  authorId: Yup.number().optional(),
  note: Yup.string(),
})
const appointmentSelections = Yup.object({
  startDate: Yup.string().required(),
  endDate: Yup.string().required("You haven't selected a timeslot."),
  roomId: Yup.string().required(),
})

const appointmentCreateSchema = Yup.object({
  id: Yup.string().optional(),
  title: Yup.string().optional(),
  // An array of objects containing startDate and endDate
  selections: Yup.array().of(appointmentSelections).min(1).required(),
  // startDate: Yup.string().required(),
  // endDate: Yup.string().required("You haven't selected a timeslot."),
  typeId: Yup.string().required(),
  statusId: Yup.string().required(),
  existingPatients: Yup.array().of(existingPatientSchema.required()).defined(),
  newPatients: Yup.array().of(newPatientSchema.required()).defined(),
  patientValidation: Yup.boolean().test('At least 1 patient', 'You must select at least 1 patient', function (val) {
    const { existingPatients, newPatients } = this.parent
    return existingPatients.length + newPatients.length >= 1
  }),
  notes: Yup.array().of(appointmentNote).required(),
})

export default appointmentCreateSchema
export type AppointmentCreateSchemaType = Yup.InferType<typeof appointmentCreateSchema>
