import { Plus } from 'components/Icons'
import { Field, FieldArray, useFormikContext } from 'formik'
import React, { Fragment, useMemo } from 'react'
import { Badge, Button, Col, Form, Row } from 'react-bootstrap'
import { PatientTabProps } from '../PatientInfo'
import TravelTable from '../tables/TravelTable'
import useData from 'hooks/useData'
import { Accordion } from 'react-bootstrap'
import moment from 'moment'
import { PatientInfoSchemaType } from 'schemas/patient-info-schema'

const TravelTab: React.FC<PatientTabProps> = ({ isEditing }) => {
  const { values, handleChange, isSubmitting, errors } = useFormikContext<Patient>()
  const data = useData()

  const { tripCompanions, tripPurposes } = {
    tripCompanions: data.tripCompanions.all(),
    tripPurposes: data.tripPurposes.all(),
  }

  const newTrip = {
    title: 'New trip',
    companions: [],
    purposes: [],
    journeys: [],
  }

  const newJourney = {
    arrivalDate: moment().format('YYYY-MM-DD'),
    returnDate: null,
    duration: null,
    destination: {
      id: null,
    },
    accommodation: {
      id: null,
    },
  }

  const typedErrors = useMemo<PatientInfoSchemaType>(() => {return errors as unknown as PatientInfoSchemaType}, [errors])

  return (
    <FieldArray name={`trips]`}>
      {({ push: addTrip, remove: removeTrip }) => {
        const handleAddTrip: React.MouseEventHandler<HTMLButtonElement> = async e => {
          e.preventDefault()
          addTrip({ ...newTrip })
        }

        return (
          <div className="w-100">
            <Row>
              <Col>
                <div className="d-flex justify-content-between align-items-center mb-5">
                  <h5 className="mb-0">Trips</h5>
                  <Button
                    size="sm"
                    className="d-flex gap-2 align-items-center"
                    onClick={handleAddTrip}
                    disabled={!isEditing || isSubmitting}
                  >
                    Add trip <Plus />
                  </Button>
                </div>
              </Col>
            </Row>
            <Accordion>
              {values.trips.map((trip, index) => {
                const tripErrors = errors.trips && typedErrors.trips.length > 0 ? typedErrors.trips[index] : undefined
                const tripNameError = tripErrors?.title
                const tripPurposeError = tripErrors?.purposes as unknown as string
                const tripCompanionsError = tripErrors?.companions as unknown as string

                return (
                  <Accordion.Item key={index} eventKey={index.toString()}>
                    <Accordion.Header className="">
                      <div className="d-flex gap-4 align-items-center">
                        <span>{values.trips[index].title}</span>
                        {values.trips[index].id === undefined ? <Badge className="bg-danger">unsaved data</Badge> : ''}
                      </div>
                    </Accordion.Header>

                    <Accordion.Body>
                      <div className="trip-container pt-4 mb-5 rounded p-3">
                        <Row className="g-5 mb-5">
                          <Col>
                            {values.trips[index] ? (
                              <>
                                <Field
                                  as={Form.Control}
                                  disabled={!isEditing || isSubmitting}
                                  name={`trips[${index}].title`}
                                  isInvalid={Boolean(tripNameError)}
                                />
                                <Form.Control.Feedback type="invalid">{tripNameError}</Form.Control.Feedback>
                              </>
                            ) : (
                              ''
                            )}
                          </Col>
                        </Row>
                        <Row className="mb-5">
                          <Col xs="auto">
                            <FieldArray name={`trips[${index}].purposes`}>
                              {({ push: addPurpose, remove: removePurpose }) => {
                                return (
                                  <>
                                    {values.trips[index] ? (
                                      <>
                                        <h4 className="h5 mb-4">Purpose</h4>
                                        {tripPurposes.map(purpose => {
                                          const isActive = values.trips[index].purposes.filter(
                                            tripPurpose => tripPurpose.id === purpose.id
                                          ).length
                                          return (
                                            <Field
                                              key={purpose.id}
                                              as={Form.Check}
                                              type="checkbox"
                                              id={`trips[${index}]:purpose:${purpose.id}`}
                                              value={purpose.id}
                                              checked={isActive}
                                              onChange={e => {
                                                if (isActive) {
                                                  const _item = values.trips[index].purposes.find(
                                                    tripPurpose => tripPurpose.id === purpose.id
                                                  )
                                                  if (_item) {
                                                    const _index = values.trips[index].purposes.indexOf(_item)
                                                    removePurpose(_index)
                                                  }
                                                } else {
                                                  addPurpose(purpose)
                                                }
                                              }}
                                              disabled={!isEditing || isSubmitting}
                                              label={purpose.title}
                                              isInvalid={Boolean(tripPurposeError)}
                                            />
                                          )
                                        })}
                                        {tripPurposeError && <p className="invalid-feedback d-block">{tripPurposeError}</p>}
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </>
                                )
                              }}
                            </FieldArray>
                          </Col>
                          <Col xs="auto">
                            <FieldArray name={`trips[${index}].companions`}>
                              {({ push: addCompanion, remove: removeCompanion }) => {
                                return (
                                  <>
                                    {values.trips[index] ? (
                                      <>
                                        <h4 className="h5 mb-4">Travelling with</h4>
                                        {tripCompanions.map(companion => {
                                          const isActive = values.trips[index].companions.filter(
                                            tripCompanion => tripCompanion.id === companion.id
                                          ).length
                                          return (
                                            <Field
                                              key={companion.id}
                                              as={Form.Check}
                                              type="checkbox"
                                              id={`trips[${index}]:companion:${companion.id}`}
                                              value={companion.id}
                                              checked={isActive}
                                              onChange={e => {
                                                if (isActive) {
                                                  const _item = values.trips[index].companions.find(
                                                    tripCompanion => tripCompanion.id === companion.id
                                                  )
                                                  if (_item) {
                                                    const _index = values.trips[index].companions.indexOf(_item)
                                                    removeCompanion(_index)
                                                  }
                                                } else {
                                                  addCompanion(companion)
                                                }
                                              }}
                                              disabled={!isEditing || isSubmitting}
                                              label={companion.title}
                                              isInvalid={Boolean(tripCompanionsError)}
                                            />
                                          )
                                        })}
                                        {tripCompanionsError && <p className='invalid-feedback d-block'>{tripCompanionsError}</p>}
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </>
                                )
                              }}
                            </FieldArray>
                          </Col>
                        </Row>
                        <Row className="g-5">
                          <Col xs={12}>
                            {values.trips[index] ? (
                              <FieldArray name={`trips[${index}].journeys`}>
                                {({ push: addJourney, remove: removeJourney }) => (
                                  <>
                                    <h4 className="h5 mb-4">Journeys</h4>
                                    <TravelTable
                                      tripIndex={index}
                                      isEditing={isEditing}
                                      handleAdd={addJourney}
                                      handleRemove={removeJourney}
                                      handleChange={handleChange}
                                    />
                                    <div className="d-flex justify-content-center align-item-cener py-4">
                                      <Button
                                        size="sm"
                                        disabled={!isEditing || isSubmitting}
                                        onClick={() => {
                                          addJourney({ ...newJourney })
                                        }}
                                      >
                                        Add journey <Plus />
                                      </Button>
                                    </div>
                                    {isEditing ? (
                                      <Button
                                        size="sm"
                                        className=""
                                        disabled={!isEditing || isSubmitting}
                                        variant="danger text-white"
                                        onClick={() => removeTrip(index)}
                                      >
                                        Delete
                                      </Button>
                                    ) : (
                                      ''
                                    )}
                                  </>
                                )}
                              </FieldArray>
                            ) : (
                              ''
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )
              })}
            </Accordion>
          </div>
        )
      }}
    </FieldArray>
  )
}

export default TravelTab
